import {
  AfterViewInit, ChangeDetectorRef,
  Component, Input, OnDestroy, OnInit,
  ViewEncapsulation
} from '@angular/core';
import {
  LoadingIndicatorService
} from './loading-indicator.service';
import {
  Subject, Subscription
} from 'rxjs';
import {delay, takeUntil} from "rxjs/operators";


@Component({
  selector: 'app-loading-indicator',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './loading-indicator.template.html',
})
export class LoadingIndicatorComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input('type') type: string = "spinner";
  @Input('diameter') diameter: number = 18;
  public loading = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private loadingSubscription:Subscription;

  constructor( public loadingService: LoadingIndicatorService,private cd: ChangeDetectorRef) {

  }

  public ngOnInit(): void {

  }

  public ngAfterViewInit(): void {
    this.loadingService.pipe(takeUntil(this.ngUnsubscribe)).subscribe((status) => {
      this.loading = status;
      //console.log("Loading indicator got: " + this.loading);
      this.cd.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

<h1>Manufacturing Activity</h1>
<div class="panel">
  <kendo-grid
    [data]="view | async"
    [height]="490"
    [skip]="state.skip"
    [pageable]="true"
    [pageSize]="state.take"
    [sortable]="false"
    [selectable]="false"
    (dataStateChange)="dataStateChange($event)">
    <ng-template kendoGridToolbarTemplate [position]="'top'">
      <kendo-buttongroup [selection]="'single'">
        <button
          *ngFor="let button of statuses"
          kendoButton
          [toggleable]="true"
          [selected]="button.selected"
          (selectedChange)="selectedChange($event, button)"
        >{{button.text}}</button>
      </kendo-buttongroup>
      <kendo-datepicker [value]="start" [(value)]="start" (valueChange)="dateRangeChanged($event)"></kendo-datepicker>
      <kendo-datepicker [value]="end" [(value)]="end" (valueChange)="dateRangeChanged($event)"></kendo-datepicker>
      <button style="margin-left:20px;" type="button" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
    </ng-template>
    <kendo-grid-column field="_id" title="Order ID" width="125" [headerClass]="'grid-column-header'">
    </kendo-grid-column>
    <kendo-grid-column title="Ordered" width="125" [headerClass]="'grid-column-header'">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.ordered | date}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Status" width="200" [headerClass]="'grid-column-header'">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.status | uppercase }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Shipped" width="100" [headerClass]="'grid-column-header'">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <kendo-icon [name]="'checkbox-checked'" *ngIf="dataItem.shipped"></kendo-icon>
        <kendo-icon [name]="'checkbox'" *ngIf="!dataItem.shipped"></kendo-icon>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Shipped On" width="125" [headerClass]="'grid-column-header'">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.shipped | date}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Tracking" width="175" [headerClass]="'grid-column-header'">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.tracking }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Received On" width="150" [headerClass]="'grid-column-header'">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.received | date}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Client Tracking" width="200" [headerClass]="'grid-column-header'">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.clientTracking }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-excel [fileName]="getFileName()" [fetchData]="allData">
      <kendo-excelexport-column field="_id" title="Order ID">
      </kendo-excelexport-column>
      <kendo-excelexport-column field="ordered" title="Ordered">
      </kendo-excelexport-column>
      <kendo-excelexport-column field="status" title="Status">
      </kendo-excelexport-column>
      <kendo-excelexport-column field="tracking" title="Tracking">
      </kendo-excelexport-column>
      <kendo-excelexport-column field="received" title="Received">
      </kendo-excelexport-column>
      <kendo-excelexport-column field="clientTracking" title="Tracking">
      </kendo-excelexport-column>
    </kendo-grid-excel>
  </kendo-grid>
</div>
<span style="font-size:8px;margin-top:10px;display:block;">build 15</span>

<div fxHide.lt-md style="display:flex;flex-direction: row;justify-content: flex-start;align-items:center;position:relative;">
  <h3 *ngIf="location"><span>{{location.name}}</span></h3> <app-loading-indicator style="margin-left:20px;"></app-loading-indicator>
</div>
<div>
  <kendo-dropdownbutton [look]="'flat'" [data]="userOption" [popupSettings]="{ align: 'right', popupClass: 'pclass'}" [textField]="'actionName'"><kendo-icon [name]="'user'"></kendo-icon>
    <ng-template kendoDropDownButtonItemTemplate let-dataItem>
      <kendo-icon [name]="dataItem.icon"></kendo-icon>
      <div style="padding:10px;display: flex;flex-direction: column;align-items:flex-start;"><div>{{ dataItem.actionName }}</div><div style="font-size:10px;">{{dataItem.subtitle}}</div></div>
    </ng-template>
  </kendo-dropdownbutton>
</div>

<h1>Shipments</h1>
<div class="panel">
  <kendo-grid
    [data]="view | async"
    [skip]="state.skip"
    [pageable]="true"
    [pageSize]="state.take"
    [sortable]="false"
    [selectable]="true"
    (selectionChange)="selectedRow($event)"
    (dataStateChange)="dataStateChange($event)">
    <ng-template kendoGridToolbarTemplate [position]="'top'">
      <button style="margin-left:20px;" type="button" kendoButton [disabled]="selectedShipment == null || loadingPackingList" (click)="getPackingListLink()" [icon]="loadingPackingList ? 'loading' : 'print'">Packing List</button>
    </ng-template>
    <kendo-grid-column field="shipped" title="Shipped" width="125" [headerClass]="'grid-column-header'">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.shipped | date : 'MMM dd YYYY - EEE'}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="tracking" title="Tracking" width="125" [headerClass]="'grid-column-header'">
    </kendo-grid-column>
    <kendo-grid-column field="carrier" title="Carrier" width="125" [headerClass]="'grid-column-header'">
    </kendo-grid-column>
    <kendo-grid-column field="total" title="Total" width="125" [class]="'grid-column right'" [headerClass]="'grid-column-header right'">
    </kendo-grid-column>

  </kendo-grid>
</div>

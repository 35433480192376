import {
  NgModule
} from '@angular/core';
import {NavigationComponent} from './navigation.component';
import {ButtonsModule} from '@progress/kendo-angular-buttons';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {UserContext} from '../services/user-context';
import {IconComponent, IconModule} from '@progress/kendo-angular-icons';


@NgModule({

  imports: [
    ButtonsModule,
    CommonModule,
    RouterModule,
    IconModule
  ],
  declarations: [
    NavigationComponent
  ],
  exports: [
    NavigationComponent
  ],
  providers: [
    UserContext
  ],
  entryComponents: [],
})
export class NavigationModule {
}

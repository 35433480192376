/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:6a80eef3-117a-42e0-9c80-96cb363e193d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_FaXQGX9zY",
    "aws_user_pools_web_client_id": "6juvonnlsl19126mcgceao1ia2",
    "oauth": {},
    "aws_content_delivery_bucket": "manufacturing-20210109225159-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d3ip76zk5f2ipo.cloudfront.net"
};


export default awsmobile;

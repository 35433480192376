import * as _ from 'lodash';

import {
  Component,
  ViewEncapsulation,
  Input, Inject, OnInit, ChangeDetectorRef, OnDestroy
} from '@angular/core';

import {
  Router,
  ActivatedRoute,
  NavigationEnd
} from '@angular/router';

import {DOCUMENT} from '@angular/common';
import {Subscription} from 'rxjs';
import {UserContext} from '../services/user-context';
import {Util} from '../util/util.service';
import {Auth} from '@aws-amplify/auth';

@Component({
  selector: 'app-navigation',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './navigation.template.html',
  styleUrls: [
    './navigation.style.scss'
  ]
})
export class NavigationComponent implements OnInit, OnDestroy {
  @Input()
  routeLinks: any[] = [
    {label: 'Dashboard', link: '/dashboard', icon: "grid", space:true},
    {label: 'Shipping', link: '/shipping', icon: "barcode", space:false}
  ];

  accountRequiredLinks: any[] = [];
  locations:any[];

  accountLinks: any[] = [
    /*
    {label: 'Account', path: '/accounts/:id/', icon: "settings", space:true},
    {label: 'Users', path: '/accounts/:id/users/', icon: "person"},
    {label: 'Locations', path: '/accounts/:id/locations/', icon: "location_on"},
    {label: 'Import', path: '/accounts/:id/import/', icon: "cloud_upload", space:true},
    {label: 'Export', path: '/accounts/:id/export/', icon: "cloud_download"},
    {label: 'Waivers', path: '/accounts/:id/waivers/', icon: "assignment_turned_in", space:true}

     */
  ];

  public previousPath: string;
  brandLogo = "/assets/flh8.svg";

  private locationsSubscription: Subscription;

  constructor(@Inject(DOCUMENT) private document: Document,
              private router: Router,
              private util: Util,
              private userContext: UserContext,
              private cd: ChangeDetectorRef,
              private activatedRoute: ActivatedRoute ) {

    //this.accountSubscription = this.userContext.accountObserver.subscribe( (account) => {
    /*
      this.addLink(this.accountLinks, {label: 'Publish', path: '/accounts/:id/publication/', icon: "web"}, ["publish"], ["administrator"] );
      this.addLink(this.accountRequiredLinks, {label: 'Telehealth', link: '/telehealth', icon: "videocam"}, ["telemedicine", "emr"] );
      this.addLink(this.accountRequiredLinks, {label: 'Teleconsult', link: '/telehealth', icon: "videocam"}, ["telecart"], null, "Telehealth" );
      this.addLink(this.accountLinks, {label: 'Curations', path: '/accounts/:id/curate/', icon: "view_module", space:true}, ["telecart"] );
      this.addLink(this.routeLinks, {label: 'Orders', link: '/orders', icon: "shopping_basket", space:true}, ["orthotics"], ["administrator"] );

     */
    //});

    this.brandLogo = `/assets/logo.svg`;


    //this.addLink(this.routeLinks, {label: 'Accounts', link: '/accounts', icon: "business", space:true}, null, ["administrator"] );

    this.router.events.subscribe((event: any) => {

      if (event instanceof NavigationEnd) {

        const lastActivatedRoute = this.util.getLastActivatedRoute(this.activatedRoute);
        this.previousPath = this.util.getFullRoutePathByActivatedRoute('', lastActivatedRoute);
      }
    });

    this.locationsSubscription = this.userContext.locationsObserver.subscribe((locations) => {
      this.locations = locations;
      //this.cd.detectChanges();
      //console.log("Updated nav locations to : " + JSON.stringify(this.locations,null,4));
    });

  }

  private addLink( collection:any[], item:any, options?:string[], roles?:string[], exclude?:string ) {
    let shouldAdd = false;
    let couldAdd = false;
    if( options ) {
      for (const option of options) {
        if (false) {
          shouldAdd = true;
          break;
        }
      }
    }
    if( roles ) {
      for (const role of roles) {
        if (false) {
          shouldAdd = true;
          break;
        }
      }
    }
    //console.log("About to add " + item.label + " " + shouldAdd + " " + couldAdd);
    if( (shouldAdd || couldAdd) && !collection.filter(c => c.label === item.label ).length && ( !exclude || !collection.filter(c => c.label === exclude ).length)) {
      collection.push(item);
      //console.log("\tAdded " + item.label);
    } else if( !shouldAdd && !couldAdd ) {
      for (const [i, n] of collection.entries()) {
        if( n.label === item.label) {
          collection.splice(i, 1);
        }
      }
    }
  }

  public ngOnInit(): void {
  }

  public linkFor(link:any): string[] {
    return [link.link];
  }

  public selectLocation(location:any) {
      this.userContext.locationObserver.next(location);
  }

  public iconFor(location:any): string {
    return "fitness_center";
  }

  public accountLinkFor(link:any): string[] {
    let paths = link.path.split("/").map(p => p === ":id" ? '0' : p);
    paths.pop();
    return paths;
  }

  public colorFor(link:any): string {
    //console.log("comparing " + link.link + " to " + this.previousPath);
    return (this.previousPath || "").startsWith(link.link) ? "#4a5ec9" : "#444";
  }

  public colorForLocation(location:any): string {
    //console.log("comparing " + link.link + " to " + this.previousPath);
    return location.id === (this.userContext.locationObserver.value || {}).id ? "#4a5ec9" : "#444";
  }

  public classesForLocation(location:any): string {
    //console.log("comparing " + link.link + "/" + link.path + " to " + this.previousPath);
    let classes = [];
    if( location.id === (this.userContext.locationObserver.value || {}).id ) {
      classes.push('selected');
    }
    return classes.join(" ");
  }

  public classesFor(link:any): string {
    //console.log("comparing " + link.link + "/" + link.path + " to " + this.previousPath);
    let classes = [];
    if( (link.link && (this.previousPath || "").endsWith(link.link + "/")) || (link.path && this.previousPath === link.path )) {
      classes.push('selected');
    }
    if( link.space ) {
      classes.push('section');
    }
    return classes.join(" ");
  }

  public logout() {
    Auth.signOut();
  }

  ngOnDestroy(): void {
    this.locationsSubscription.unsubscribe();
  }


}

import {Component, OnInit, ViewChild} from '@angular/core';
import {State} from '@progress/kendo-data-query';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {DataBindingDirective, DataStateChangeEvent, GridDataResult} from '@progress/kendo-angular-grid';
import {Apollo, gql} from 'apollo-angular';
import {ExcelExportData} from '@progress/kendo-angular-excel-export';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChild(DataBindingDirective) directive;
  public view = new BehaviorSubject<GridDataResult>({
    data: [],
    total: 0
  });
  public state: State = {
    skip: 0,
    take: 10
  };

  status = null;
  currentDate = new Date();
  start:Date = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
  end:Date = new Date();

  public statuses = [
    { text: 'All', status: '', selected: true },
    { text: 'Inbound', status: 'inbound' },
    { text: 'Production', status: 'production' },
    { text: 'Shipped', status: 'shipped'},
    { text: 'Received', status: 'received'},
  ];

  constructor(private apollo: Apollo ) {
    this.allData = this.allData.bind(this);
    this.getOrders();
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.getOrders();
  }


  ngOnInit(): void {
  }

  getFileName(): string {
    return `Orders_${this.status ? this.status.toUpperCase() : 'ALL'}_${new Date().toISOString().substring(0, 10)}.xlsx`;
  }

  public allData = async (): Promise<ExcelExportData> => {
    let skip = 0;
    let input = {
      start: this.start.toISOString(),
      end: this.end.toISOString(),
      skip: skip,
      limit: 100
    };
    if(this.status !== "all") {
      input["status"] = this.status;
    }


    let data = [];
    let total = 0;
    do {
      console.log("Sending all data input: " + JSON.stringify(input));
      let result = await this.apollo.query({
        variables: {
          input: input
        },
        query: gql`
          query pagedOrders($input:PagedOrdersInput) {
            pagedOrders(input:$input) {
              data {
                _id
                produced
                received
                ordered
                shipped
                status
                tracking
                clientTracking
              }
              total
            }
          }
        `
      }).toPromise();

      data = data.concat(result.data["pagedOrders"].data);
      total = result.data["pagedOrders"].total;
      input.skip = data.length;
    } while( data.length < total );

    return <ExcelExportData>{
        data: data,
        total: total
    };
  }

  public getOrders() {
    let input = {
      start: this.start.toISOString(),
      end: this.end.toISOString(),
      skip: this.state.skip,
      limit: this.state.take
    };
    if(this.status !== "all") {
      input["status"] = this.status;
    }
    console.log("Sending intput: " + JSON.stringify(input));
    this.apollo.watchQuery({
      variables: {
        input: input
      },
        query: gql`
            query pagedOrders($input:PagedOrdersInput) {
                pagedOrders(input:$input) {
                    data {
                        _id
                        produced
                        received
                        ordered
                        shipped
                        status
                        tracking
                        clientTracking
                    }
                    total
                }
            }
        `
    })
    .valueChanges.subscribe(r => {
      console.log(r);
      this.view.next({
        data: r.data["pagedOrders"].data,
        total: r.data["pagedOrders"].total
      })
    })
  }


  public selectedChange(e, btn) {
    this.status = btn.status;
    this.getOrders();
    this.state.skip = 0;
  }

  public dateRangeChanged(e) {
    this.getOrders();
  }

}

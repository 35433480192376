import {Component, OnInit, ViewChild} from '@angular/core';
import {State} from '@progress/kendo-data-query';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {DataBindingDirective, DataStateChangeEvent, GridDataResult, SelectionEvent} from '@progress/kendo-angular-grid';
import {Apollo, gql} from 'apollo-angular';
import {ExcelExportData} from '@progress/kendo-angular-excel-export';

@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.scss']
})
export class ShippingComponent implements OnInit {
  @ViewChild(DataBindingDirective) directive;
  public view = new BehaviorSubject<GridDataResult>({
    data: [],
    total: 0
  });
  public state: State = {
    skip: 0,
    take: 10
  };

  public selectedShipment: any;
  public loadingPackingList = false;

  constructor(private apollo: Apollo ) {

  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    //this.getShipments();
  }


  ngOnInit(): void {
    this.getShipments();
    console.log("Getting shipents...");
  }

  public getShipments() {
    this.apollo.watchQuery({
        query: gql`
          query RecentShipments {
            recentShipments {
              shipments {
                tracking
                shipped
                carrier
                total
              }
            }
          }`
    }).valueChanges.subscribe(r => {
      console.log(r);

      this.view.next({
        data: r.data['recentShipments'].shipments,
        total: r.data['recentShipments'].shipments.length
      });
    });
  }

  public getPackingListURL( tracking: string) {
    this.apollo.watchQuery({
      variables: {
        input: tracking
      },
      query: gql`
        query PackingList($input: String){
          packingList(input: $input) {
            url
          }
        }`
    }).valueChanges.subscribe(r => {
      console.log(r);
      window.open(r.data['packingList'].url, "_blank");
      this.loadingPackingList = false;
    });
  }

  public getPackingListLink() {
    this.loadingPackingList = true;
    this.getPackingListURL(this.selectedShipment.tracking);
  }
  public selectedRow(event: SelectionEvent) {
    this.selectedShipment = event.selectedRows[0].dataItem;
  }

}

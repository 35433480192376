import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import {AuthGuard} from './security/auth.guard';
import {NotificationService} from '@progress/kendo-angular-notification';
import {HttpInterceptorProviders} from './interceptors/interceptor-provider';
import {LoadingIndicatorService} from './navigation/loading-indicator';
import {ChartModule} from '@progress/kendo-angular-charts';
import {RouterModule} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NavigationModule} from './navigation/navigation.module';
import {MainLayoutModule} from './layout/main-layout.module';
import {PageHeaderModule} from './layout/page-header/page-header.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {ExcelModule, GridModule, PagerModule} from '@progress/kendo-angular-grid';
import {GaugesModule} from '@progress/kendo-angular-gauges';
import {DialogModule} from '@progress/kendo-angular-dialog';
import {ButtonsModule} from '@progress/kendo-angular-buttons';
import {LabelModule} from '@progress/kendo-angular-label';
import {InputsModule} from '@progress/kendo-angular-inputs';
import {IconModule} from '@progress/kendo-angular-icons';
import {ToolBarModule} from '@progress/kendo-angular-toolbar';
import {DateInputsModule} from '@progress/kendo-angular-dateinputs';
import { GraphQLModule } from './graphql.module';
import {ExcelExportModule} from '@progress/kendo-angular-excel-export';
import {ShippingComponent} from "./shipping/shipping.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    ShippingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AmplifyUIAngularModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    PageHeaderModule,
    MainLayoutModule,
    NavigationModule,
    ChartModule,
    PagerModule,
    GaugesModule,
    DialogModule,
    ButtonsModule,
    LabelModule,
    InputsModule,
    FormsModule,
    ReactiveFormsModule,
    GridModule,
    IconModule,
    ToolBarModule,
    DateInputsModule,
    ExcelModule,
    ExcelExportModule,
    GraphQLModule
  ],
  exports: [
    RouterModule,
    ChartModule
  ],
  providers: [
    AuthGuard,
    LoadingIndicatorService,
    NotificationService,
    HttpInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

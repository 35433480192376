import {
  Injectable
} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Auth, CognitoUser} from '@aws-amplify/auth';
import {Observable as zObservable} from 'zen-observable-ts';
import API, {graphqlOperation} from '@aws-amplify/api';

@Injectable()
export class UserContext {
  private currentUser: CognitoUser;
  private userSubscription: Subscription;
  private locationsSubscription: Subscription;
  private locationUpdateSubscription: any;
  //private locationCapacitySubscription: any;
  public userInfo:any;
  public locations:any[] = [];

  userObserver: BehaviorSubject<CognitoUser> = new BehaviorSubject<CognitoUser>(null);
  locationObserver: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  locationsObserver: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);

  constructor() {
    this.locationsSubscription = this.locationsObserver.subscribe((locations) => {
      this.locations = locations;
      //console.log("Locations: " + JSON.stringify(this.locations,null,4));
    });

    this.userSubscription = this.userObserver.subscribe((user) => {
      this.currentUser = user;
    });

  }

  updateLocation(location:any) {
    for( const l of this.locationsObserver.value ) {
      if (l.id == location.id) {
        Object.assign(l, location);
        break;
      }
    }
    if( (this.locationObserver.value || {}).id == location.id) {
       this.locationObserver.next(location);
    }
  }

  reset() {
    this.userObserver.next(null);
    this.locationObserver.next(null);
    this.locationsObserver.next(null);
  }

}

import {
  NgModule
} from '@angular/core';

import {
  MainLayoutComponent
} from './main-layout.component';
import {Util} from '../util/util.service';
import {UserContext} from '../services/user-context';
import {PageHeaderModule} from './page-header/page-header.module';
import {NavigationModule} from '../navigation/navigation.module';
import {RouterModule} from '@angular/router';
import {DialogModule} from '@progress/kendo-angular-dialog';

@NgModule({
  imports: [
    NavigationModule,
    PageHeaderModule,
    RouterModule,
    DialogModule
  ],
  declarations: [
    MainLayoutComponent
  ],
  exports: [
    MainLayoutComponent
  ],
  providers: [
    Util,
    UserContext
  ],
  entryComponents: [],
})
export class MainLayoutModule {
}

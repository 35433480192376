import {Component, NgZone, OnInit} from '@angular/core';
import { Auth, Hub } from 'aws-amplify';
import {UserContext} from '../services/user-context';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private router: Router,
    private userContext: UserContext,
    private zone: NgZone) {


    // Used for listening to login events
    Hub.listen("auth", ({ payload: { event, data } }) => {
      console.log("Got event " + event);
      if (event === "cognitoHostedUI" || event === "signIn") {
        console.log(event);
        Auth.currentAuthenticatedUser()
          .then((user) => {
            console.log("access token: " + JSON.stringify(user.signInUserSession.idToken,null,4));
            this.userContext.userObserver.next(user);
            Auth.currentUserInfo().then((info) => {
              console.log("Current user info after login: " + JSON.stringify(info));
              this.userContext.userInfo = info;
            });
          }).catch((err) => {
          console.log(err);
        });
        this.zone.run(() => this.router.navigate(['/dashboard']));
      } else {
      }
    });


    //currentAuthenticatedUser: when user comes to login page again
    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log(JSON.stringify(user,null,4));
        this.userContext.userObserver.next(user);
        Auth.currentUserInfo().then((info) => {
          console.log("Current user info: " + JSON.stringify(info));
          this.userContext.userInfo = info;
        });


        //if( (user.signInUserSession.accessToken.payload["cognito:groups"] || []).includes("Users") ) {
        //  this.router.navigate(['/users'], { replaceUrl: true });
        //} else if( (user.signInUserSession.accessToken.payload["cognito:groups"] || []).includes("Owners") ) {
          this.router.navigate(['', 'dashboard'], { replaceUrl: true });
        //} else {
        //  this.router.navigate(['/noaccess'], { replaceUrl: true });
        //}
      }).catch((err) => {
      console.log(err);
    })

  }


  ngOnInit(): void {
  }

}

import * as _ from 'lodash';
import { Auth, Hub } from 'aws-amplify';
import {
  Component,
  ViewEncapsulation,
  Input, OnDestroy, OnInit
} from '@angular/core';

import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  NavigationStart
} from '@angular/router';

import {BehaviorSubject, Subscription} from 'rxjs';
import {Util} from '../../util/util.service';
import {UserContext} from '../../services/user-context';

@Component({
  selector: 'app-page-header',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './page-header.template.html',
  styleUrls: [
    './page-header.style.scss'
  ]
})
export class PageHeaderComponent implements OnInit, OnDestroy {

  term:string;

  public previousPath: string;
  public requests: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public location:any;
  private locationSubscriber:Subscription;

  userOption:Array<any> = [{
    actionName: 'Log out',
    subtitle: "Safely disconnect",
    icon: 'logout',
    click: (dataItem) => {
      Auth.signOut();
      this.router.navigate(['login']);
    }
  }];


  constructor(private router: Router,
              private util: Util,
              private userContext: UserContext,
              private activatedRoute: ActivatedRoute ) {

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        const lastActivatedRoute = this.util.getLastActivatedRoute(this.activatedRoute);
        this.previousPath = this.util.getFullRoutePathByActivatedRoute('', lastActivatedRoute);
      }
    });

    Auth.currentAuthenticatedUser()
      .then((user) => {
        this.userContext.userObserver.next(user);
        Auth.currentUserInfo().then((info) => {
          this.userContext.userInfo = info.attributes;
          console.log("Defining options " + JSON.stringify(info));
          this.userOption = [{
            actionName: (this.userContext.userInfo || {}).given_name + " " + (this.userContext.userInfo || {}).family_name,
            subtitle:"Edit profile",
            icon:"settings",
            click: (dataItem) => {
              this.router.navigate(['profile']);
            }
          },{
            actionName: 'Log out',
            subtitle:"Safely disconnect",
            icon: 'logout',
            click: (dataItem) => {
              Auth.signOut();
              this.router.navigate(['login']);
            }
          }];
        });

      }).catch((err) => {
      console.log(err);
    });




  }

  public iconFor(location:any): string {
    return "fitness_center";
  }

  public onChange($event) {
  }


  public logout() {
    Auth.signOut();
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
  }

}

import {
  NgModule
} from '@angular/core';
import {PageHeaderComponent} from './page-header.component';
import {ButtonsModule} from '@progress/kendo-angular-buttons';
import {CommonModule} from '@angular/common';
import {InputsModule} from '@progress/kendo-angular-inputs';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {RouterModule} from '@angular/router';
import {UserContext} from '../../services/user-context';
import {LoadingIndicatorModule} from '../../navigation/loading-indicator';
import {IconModule} from '@progress/kendo-angular-icons';


@NgModule({

  imports: [
    ButtonsModule,
    CommonModule,
    InputsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    LoadingIndicatorModule,
    IconModule
  ],
  declarations: [
    PageHeaderComponent
  ],
  exports: [
    PageHeaderComponent
  ],
  providers: [
    UserContext
  ],
  entryComponents: [],
})
export class PageHeaderModule {
}

import {
  Injectable
} from '@angular/core';
import {
  BehaviorSubject
} from 'rxjs';


@Injectable()
export class LoadingIndicatorService extends BehaviorSubject<boolean> {
  public state = 'initial';
  requests = {};

  constructor() {
    super(false);
    this.state = 'after';
  }


  update(url: string, loading:boolean) {
    let key = encodeURIComponent(url);
    if( loading ) {
      this.requests[key] = true;
    } else {
      delete this.requests[key];
    }
    super.next(Object.keys(this.requests).length > 0);
  }

}

import {
  Component,
  ViewEncapsulation
} from '@angular/core';


@Component({
  selector: 'app-main-layout',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './main-layout.template.html',
  styleUrls: [
    './main-layout.style.scss'
  ]
})
export class MainLayoutComponent {
}
